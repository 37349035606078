import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import ContactUs from "./pages/contact-us";
import Article from "./pages/article";

const AppRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/article' element={<Article />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </div>
    );
};

export default AppRoutes;