import React from 'react';
import './style.scss';
import contact from '../../../../data/contact-info.json';
const TopPanel = () => {
    return (
        <div className='top_panel padding_main'>
            <div className='top_info'>
                <a href={'tel:' + contact.phone} className='nth_info'>
                    <span className="icon-phone icon"></span>
                    <span className='info'>{contact.phone}</span>
                </a>
                <a href={'mailto:' + contact.email } className='nth_info'>
                    <span className="icon-mail-envelope-open icon"></span>
                    <span className='info'>{contact.email}</span>
                </a>
            </div>
        </div>
    );
};

export default TopPanel;