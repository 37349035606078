import React, {useState} from 'react';
import './style.scss';
import {useFormik} from "formik";
import CustomInput from "../../customs/customInput";
import Toast from "../../customs/toast";
import Loader from "../../customs/loader";

const ContactForm = () => {
    const [toast, setToast] = useState(false);
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: ''
        },
        onSubmit: (values, {resetForm}) => {
            if (!Object.keys(formik.errors).length) {
                setLoading(true);
                fetch('https://www.bakersfieldtraffic.school/back_end/api/email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': "*"
                    },
                    body: JSON.stringify({...formik.values})
                })
                    .then(res => {
                        setLoading(false);
                        if (res.ok) {
                            setToast(true);
                            resetForm({values: ''})
                        }
                    })
            }
        },
        validate: (values) => {
            let errors = {};
            if (!values.name) {
                errors.name = 'Name is required.'
            }
            if (!values.email) {
                errors.email = 'Email name is required.'
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email.'
            }
            if (!values.subject) {
                errors.subject = 'Subject is required.'
            } else if (values.subject.length < 3) {
                errors.subject = 'Too short !'
            }
            return errors;
        }
    })

    return (
        <form onSubmit={formik.handleSubmit} noValidate className='contact_form'>
            <CustomInput
                type='text'
                name='name'
                label='Your name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && formik.errors.name}
            />
            <CustomInput
                type='text'
                name='email'
                label='Your email'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && formik.errors.email}
            />
            <CustomInput
                type='text'
                name='subject'
                label='Subject'
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && formik.errors.subject}
            />
            <CustomInput
                type='text'
                name='message'
                label='Your message (optional)'
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && formik.errors.message}
                textarea
            />
            <div className='btn'>
                <button type='submit' disabled={loading}>Submit</button>
                {loading && <Loader/>}
            </div>
            {toast && <Toast setToast={setToast} text='Your request was sent successfully'/>}
        </form>
    );
};

export default ContactForm;