import React from 'react';
import './style.scss';
const Toast = ({text, setToast}) => {

    setTimeout(() => {
        setToast(false)
    }, 3000)
    return (
        <div className='toast'>
            <p>{text}</p>
        </div>
    );
};

export default Toast;