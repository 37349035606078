import React, {useState} from 'react';
import './style.scss';

const CustomInput = ({type, name, label, value, onChange, error, textarea}) => {
    const [showPass, setShowPass] = useState(false);
    return (
        <div className={`group ${!!error ? 'error' : ''}`}>
            {textarea ? <textarea type={type} required id={name} name={name} value={value} onChange={onChange}/> :
                <input type={showPass ? 'text' : type} id={name} required name={name} value={value} onChange={onChange}/>}
            {type === 'password' ? showPass ? <span className="icon-eye icon_eye" onClick={() => setShowPass(!showPass)}></span> :
                <span className="icon-eye-hidden icon_eye" onClick={() => setShowPass(!showPass)}></span> : null}
            <span className="highlight"></span>
            <span className="bar"></span>
            <label htmlFor={name}>{label}</label>
            {error && <p className='error_message'>{error}</p>}
        </div>
    )
};

export default CustomInput;