import React from 'react';
import Banner from "../../components/home/banner";
import ChooseUs from "../../components/home/chooseUs";
import HelpfulTopics from "../../components/home/helpfulTopics";
import Faqs from "../../components/home/faqs";
import ServicesBoxes from "../../components/home/servicesBoxes";
import Articles from "../../components/home/articles";
const Home = () => {
    return (
        <div className='main_page home_page'>
            <Banner/>
            <ServicesBoxes/>
            <ChooseUs/>
            <HelpfulTopics/>
            <Faqs/>
            {/*<Articles />*/}
        </div>
    );
};

export default Home;