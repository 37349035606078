import React, {useEffect, useState} from 'react';
import {REQUEST_URL} from "../../constant";
import {Link} from "react-router-dom";
import './style.scss';
const Articles = ({currentId}) => {
    const [articlesData, setArticlesData] = useState();
    console.log(currentId, 'currentId--')

    useEffect(() => {
        fetch(`${REQUEST_URL}/api/articles?populate=deep`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(result => setArticlesData(result.data))
            .catch(err => console.log(err, 'err'))
    }, [])

    if(!articlesData) {
        return (
            <p>Loading ...</p>
        )
    }

    return (
        <div className='articles_section articles'>
            {articlesData.map((item, idx) => {
                const article = item.attributes.article;
                if(item.id != currentId) {
                    return (
                        <div key={item.id + idx} className='nth_article'>
                            <div className='image'>
                                <img src={REQUEST_URL + article.image.data.attributes.url}
                                     alt='Article'/>
                            </div>
                            <div className='article_nth_info'>
                                <h5 className='date'>{article.date}</h5>
                                <h4 className='text'>{article.title}</h4>
                                <Link to={'/article?id=' + item.id}>
                                    <div className='btn'>
                                        <button>Read More</button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )
                }

            })}
        </div>
    );
};

export default Articles;