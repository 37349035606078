import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import TopPanel from "./topPanel";
import LogoSrc from '../../../assets/images/logo.png';
import {Link, useLocation, useRoutes} from "react-router-dom";
const Header = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const ref = useRef();

    useEffect(() => {
        setOpen(false);
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [location])

    return (
        <>
            <TopPanel/>
            <header className='padding_main'>
                <div className='image'>
                    <Link to='/'><img src={LogoSrc} alt='Logo'/></Link>
                </div>
                <nav className='desktop_nav'>
                    <ul className='nav_bar'>
                        <li className='nav_item'>
                            <Link to='/'>Home</Link>
                        </li>
                        <li className='nav_item'>
                            <a href='https://www.rapidtrafficschool.com/student/register/' target='_blank'>Register</a>
                        </li>
                        <li className='nav_item'>
                            <Link to='/#faqs'>FAQs</Link>
                        </li>
                        <li className='nav_item'>
                            <Link to='/contact-us'>Contact Us</Link>
                        </li>
                    </ul>
                </nav>
                <div className='mobile_menu'>
                    <span className="icon-menu icon" onClick={() => setOpen(true)}></span>
                    <div className={`back_filter ${open ? 'opened' : 'closed'}`} onClick={(e) => {
                        if (ref.current && !ref.current.contains(e.target)) {
                            e.preventDefault();
                            setOpen(false)
                        }
                    }
                    }>

                        <nav className='mobile_nav' ref={ref}>
                            <div className='image'>
                                <Link to='/'><img src={LogoSrc} alt='Logo'/></Link>
                            </div>
                            <ul className='nav_bar'>
                                <li className='nav_item'>
                                    <Link to='/'>Home</Link>
                                </li>
                                <li className='nav_item'>
                                    <Link to='/'>Register</Link>
                                </li>
                                <li className='nav_item'>
                                    <a href='/#faqs'>FAQs</a>
                                </li>
                                <li className='nav_item'>
                                    <Link to='/contact-us'>Contact Us</Link>
                                </li>
                            </ul>
                            <span className="icon-x" onClick={() => setOpen(false)}></span>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;