import React, {useEffect, useState} from 'react';
import './style.scss';

const ScrollToTop = () => {
    const [show, setShow] = useState(false);
    const scrollToTopHandle = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const toggleVisible = () => {
        if(window.scrollY > 600) {
            setShow(true)
        }else {
            setShow(false)
        }
    }

    window.addEventListener('scroll', toggleVisible);
    return (
        <>
            {show ? (
                    <div className='scroll_to_top' onClick={scrollToTopHandle}>
                        <span className="icon-arrow-up icon"></span>
                    </div>)
                : null
            }
        </>

    );
};

export default ScrollToTop;