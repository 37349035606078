import React from 'react';
import './style.scss';
import data from '../../../data/serviecs-boxes.json';
const ServicesBoxes = () => {
    return (
        <div className='topics padding_main mt_30'>
            {data.map((item, idx) => (
                <figure className="snip1543" key={item.title + idx}>
                    <img src={require('../../../assets/images' + item.image)} alt="Topic"/>
                    <figcaption>
                        <h4 className='box_title'>{item.title}</h4>
                        <p>{item.desc}</p>
                        <div className='href_to'>
                            <a href={item.href} target={item.newPage ? '_blank' : ''}>{item.buttonText}</a>
                        </div>
                    </figcaption>
                </figure>
            ))}

        </div>
    );
};

export default ServicesBoxes;