import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import './style.scss';
import {REQUEST_URL} from "../../constant";
import Articles from "../../components/articles";

const Article = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [article, setArticle] = useState();
    useEffect(() => {
        fetch(`${REQUEST_URL}/api/articles/${id}?populate=deep`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(result => setArticle(result.data.attributes.article))
            .catch(err => console.log(err, 'err'))


    }, [id])

    if (!article) {
        return (
            <p>Loading ...</p>
        )
    }
    return (
        <div className="article_page main_page padding_main">
            <h1 className='title'>{article.title}</h1>
            <div className='main_image'>
                <img src={REQUEST_URL + article.image.data.attributes.url} alt='Article'/>
            </div>
            <div className='article_info'>
                <p className='desc'>{article.description}</p>
                {article.sub.length ? article.sub.map((item, idx) => (
                    <>
                        <h3 className='subTitle'>{item.title}</h3>
                        <p className='desc'>{item.description}</p>
                    </>
                )) : null}
            </div>
            <Articles currentId={id}/>
        </div>
    );
};

export default Article;