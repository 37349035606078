import React, {useEffect, useRef, useState} from 'react';
import data from '../../../data/faqs.json';
import './style.scss';
import Question from "./question";
import {useLocation} from "react-router-dom";

const Faqs = () => {
    const [select, setSelect] = useState('');
    const ref = useRef();
    const location = useLocation();

    useEffect(() => {
        if(location.hash === '#faqs') {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [location])

    return (
        <div className='faq_block padding_main mt_30' id='faqs' name='faqs' ref={ref}>
            <h2 className='title'>{data.title}</h2>
            <div className='faqs'>
                {data.faqs.map((item, idx) => (
                    <Question key={item.id + idx} item={item} setSelect={setSelect}
                              select={item.id === select ? item.answer : ''}/>
                ))}
            </div>
        </div>
    );
};

export default Faqs;