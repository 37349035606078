import React from 'react';
import './style.scss';
const Footer = () => {
    return (
        <footer>
            <div className='copyright'>
                <p>© 2022 - Long Beach Traffic School. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;