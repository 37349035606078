import React, {useState} from 'react';

const Question = ({item, select, setSelect}) => {
    return (
        <div className={`faq_nth ${select ? 'active' : ''}`} onClick={() => select && setSelect('')}>
                <div className='question' onClick={() => setSelect(item.id)}>
                    <h1 className='fs_17'>{item.question}</h1>
                    <span className="icon-chevron-up icon"></span>
                </div>
                {select && <div className='answer'>
                    <p className='fs_17'>{select}</p>
                </div>}
        </div>
    );
};

export default Question;