import React from 'react';
import './style.scss';
import ImageSrc from '../../../assets/images/banner-1.jpg';
import LoginBlock from "./loginBlock";
import data from '../../../data/banner-info.json';
const Banner = () => {
    return (
        <div style={{backgroundImage: `url(${ImageSrc})`}} className='banner_block padding_main'>
            <div className='banner_info'>
                <h2 className='title'>{data.title}</h2>
                <ul>
                    {data.services.map((item, idx) => (
                        <li key={item + idx} className='fs_17'>{item}</li>
                    ))}
                </ul>
                <div className='href_to'>
                    <a href={data.href} target='_blank'>{data.buttonText}</a>
                </div>
            </div>
            <LoginBlock />
        </div>
    );
};

export default Banner;