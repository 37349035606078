import React from 'react';
import './style.scss';
import data from '../../../data/helpful-topics.json';

const HelpfulTopics = () => {
    return (
        <div className='helpful_topics padding_main mt_30'>
            <h2 className='title'>{data.title}</h2>
            <div className='topics_section'>
                {data.topics.map((item, idx) => (
                    <a key={idx} className='nth_topic' href={item.href} target='_blank'>
                        <div className='icon'>
                            <img src={require('../../../assets/icons' + item.icon)} alt='Topic' />
                        </div>
                        <div className='info'>
                            <h3 className='title'>{item.title}</h3>
                            <h4 className='desc fs_17'>{item.desc}</h4>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default HelpfulTopics;