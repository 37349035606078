import React from 'react';
import './style.scss';
import {useFormik} from "formik";
import CustomInput from "../../../customs/customInput";

const LoginBlock = () => {
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values) => {
            window.open('https://www.rapidtrafficschool.com/student-login/', '_blank')
        },

    });

    return (
        <div className='login_block'>
            <div className='title_section'>
                <span className="icon-user icon"></span>
                <h2>Login Here</h2>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate>
                <CustomInput
                    type='text'
                    name='email'
                    label='Email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && formik.errors.email}
                />
                <CustomInput
                    type='password'
                    name='password'
                    label='Password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                />
                <button type='submit'>Login</button>
            </form>
        </div>
    );
};

export default LoginBlock;