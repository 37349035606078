import Home from "./pages/home";
import Layout from "./components/layout";
import './styles/globals.scss';
import './styles/_variables.scss';
import './assets/icomoon/style.css';
import ScrollToTop from "./components/scrollToTop";
import {BrowserRouter} from "react-router-dom";
import AppRoutes from "./Route";

function App() {

    return (
        <BrowserRouter>
            <div className="App">
                <div className='page-container'>
                    <Layout>
                        <AppRoutes />
                        <ScrollToTop/>
                    </Layout>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
