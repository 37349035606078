import React from 'react';
import Contacts from "../../components/contact-us/contacts";
import ContactForm from "../../components/contact-us/contactFrom";
import ImageSrc from '../../assets/images/contact.jpg';
import './style.scss';
import ContactInfo from "../../components/contact-us/contactInfo";

const ContactUs = () => {
    return (
        <div className='contact-page main_page'>
            <div className='image' style={{backgroundImage: `url(${ImageSrc})`}}>
                <h1>Contact Us</h1>
            </div>
            <div className='main_block padding_main'>
                <Contacts/>
                <ContactForm/>
                <ContactInfo/>
            </div>
        </div>
    );
};

export default ContactUs;