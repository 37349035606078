import React from 'react';
import './style.scss';
import data from '../../../data/why-choose-us.json';
import ImageSrc from '../../../assets/images/choose-us.jpg';
const ChooseUs = () => {
    return (
        <div className='choose_us_block padding_main mt_30'>
                <div className='info'>
                    <h2 className='title'>{data.title}</h2>
                    <p className='desc fs_17'>{data.desc}</p>
                    <div className='href_to'>
                        <a href={data.href} target='_blank'>{data.buttonText}</a>
                    </div>
                </div>
            <div className='back_filter' style={{backgroundImage: `url(${ImageSrc})`}}>
            </div>
        </div>
    );
};

export default ChooseUs;