import React from 'react';
import './style.scss';
import data from '../../../data/contact-page.json';
import ContactInfo from "../contactInfo";
const Contacts = () => {
    return (
        <div className='contacts_block'>
            <h2 className='title'>{data.title}</h2>
            <p className='desc'>{data.desc}</p>
            <ContactInfo />
        </div>
    );
};

export default Contacts;