import React from 'react';
import data from "../../../data/contact-info.json";
import './style.scss';

const ContactInfo = () => {
    return (
        <div className='contact_info'>
            <h3>Contact info</h3>
            <div className='contacts'>
                <a href={'tel:' + data.phone} className='nth_contact'>
                    <span className="icon-phone icon"></span>
                    <div>
                        <h3 className='nth_title'>Phone</h3>
                        <h4 className='desc'>{data.phone}</h4>
                    </div>
                </a>
                <a href={'mailto:' + data.email} className='nth_contact'>
                    <span className="icon-mail-envelope-open icon"></span>
                    <div>
                        <h3 className='nth_title'>Email</h3>
                        <h4 className='desc'>{data.email}</h4>
                    </div>

                </a>
                <a href={data.website} target='_blank' className='nth_contact'>
                    <span className="icon-globe icon"></span>
                    <div>
                        <h3 className='nth_title'>Website</h3>
                        <h4 className='desc'>{data.website}</h4>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default ContactInfo;